export const loadTranslator = (
  localeTranslations: any
): ((key: string, params?: Record<string, string | number>) => string) => {
  return (key: string, params: Record<string, string | number> = {}): string => {
    const translation =
      localeTranslations[key.toLowerCase()] || key;

    // Replace dynamic placeholders like {name} with provided values.
    return translation.replace(/{(\w+)}/g, (_, p: string) =>
      params[p]?.toString() || ''
    );
  };
};
